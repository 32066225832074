/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import NavBar from "../components/NavBar.jsx";

import "../styles/styles.css";
import Footer from "./Footer.jsx";

const Layout = ({ children }) => {
  return (
    <>
      <div className="App">
        <NavBar />
        <main>
          <base target="_blank" />
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
