import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { MenuItem, Menu } from "react-aim-menu";
import statenames from "../data/state_abbrev_to_name.json";
// import "../styles/styles.css";

const Footer = () => {
  return (
    <footer>
      <div>
        <Link className="footer-logo" to="/">
          Gerrymandering Project
        </Link>
        <h3>External links</h3>
        <div>
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://election.princeton.edu"
          >
            Princeton Election Consortium
          </OutboundLink>
        </div>
        <div>
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://representable.org"
          >
            Representable
          </OutboundLink>
        </div>
        <div>
          <OutboundLink href="https://twitter.com/princetongerry">
            Twitter
          </OutboundLink>
        </div>
        <h3>About</h3>
        <div>
          <Link to="/about">About</Link>
        </div>
        <div>{/* <Link to="/team">Team</Link> */}</div>
      </div>
      <div>
        <h3>Maps</h3>
        <div>
          <Link to="/redistricting-report-card">Redistricting Report Card</Link>
        </div>
        <div>
          <Link to="/redistricting-timeline-alert-map">
            Redistricting Timeline Alert
          </Link>
        </div>
        <div>
          <Link to="/reforms">Reforms</Link>
        </div>
        <div>
          <Link to="/tests">Gerrymandering tests (old)</Link>
        </div>
        <h3>Resources</h3>
        <div>
          <Link to="/resources">Resources</Link>
        </div>
        <div>
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLScx9z7g6Kd7wkTYNtsD59XmxFN6ZdRpVa2bQx2UR6XrZiQmJA/viewform?usp=sf_link"
          >
            Submit Feedback
          </OutboundLink>
        </div>
      </div>
      <div>
        <h3>States</h3>
        {Object.keys(statenames)
          .slice(0, 25)
          .map((statename, index) => {
            return (
              <div key={statename} value={statename}>
                <Link to={`/reforms/${statename}`}>
                  {statenames[statename]}
                </Link>
              </div>
            );
          })}
      </div>
      <div>
        <h3>States</h3>
        {Object.keys(statenames)
          .slice(25)
          .map((statename, index) => {
            return (
              <div key={statename} value={statename}>
                <Link to={`/reforms/${statename}`}>
                  {statenames[statename]}
                </Link>
              </div>
            );
          })}
      </div>
    </footer>
  );
};

export default Footer;
